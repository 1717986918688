import SupplyPage from 'components/pages/supply-chain-financing/SupplyPage';
import Seo from 'components/Seo';
import { graphql } from 'gatsby';
import * as React from 'react';

const Index = () => (
	<div>
		<Seo
			title='Supply chain financing | FINTEQ'
			keywords='enquiry, fill in the form, what is finteq, discover supplier financing, who is an animator, finteq offer, how to become an animator'
			description='Discover the benefits of supply chain finance. Fill out a short contact form and learn more about our offer.'
			lang='en-US'
			locale='en_US'
		/>

		<SupplyPage />
	</div>
);

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
